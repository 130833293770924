import React, { useState } from 'react'
import useMedia from 'use-media'

import Layout from '../../layout/live-coding-layout'
import { SEO, Image } from '../../components'
import { decodeQueryString, canUseWindow } from '../../utils'

import './live-coding.scss'
import OgImage from '../../assets/images/livechat-customize-chat-widget-og-image.png'

const LiveCoding = () => {
  const queryString = (canUseWindow && window.location.search) || ''
  const { action } = decodeQueryString(queryString)
  const initialState = action === 'register'
  const isWide = useMedia({ minWidth: 950 })

  const [overlay, setOverlay] = useState(initialState)

  return (
    <Layout
      overlay={overlay}
      setOverlay={setOverlay}
      eventDate="March 1, 2022"
      ctaClassName="c-btn btn-signup u-text-p5-medium"
      headerCta={{
        label: 'Join Discord',
        url: 'https://discord.gg/gRzwSaCxg4',
      }}
    >
      <SEO
        title="Live Coding Session with Developer Program"
        description="Take part in a online live coding session with the LiveChat Developer Program team. Put your design skills into practice and learn how to confidently implement your app's design with the LiveChat Design System."
        image={OgImage}
      />
      <section
        className="u-text-center u-Px-xl live-coding-bg"
        style={{ width: '100vw', height: 'calc(100vh - 90px)' }}
      >
        <Image
          type="coderGray"
          alt="live-coding"
          className="u-mb-lg u-mx-auto"
          wrapperStyles={{ width: isWide ? '30%' : '80%' }}
        />

        <div className="">
          <h2 className="u-text-p3 u-mt-0 u-mb-sm">Thank you</h2>
          <h3 className="u-text-p5">See you at the live coding!</h3>
        </div>

        <div className="u-Mt-xs u-flex u-items-center u-justify-center u-flex-col sm:u-flex-row">
          <a
            href="https://calendar.google.com/calendar/render?action=TEMPLATE&dates=20220301T160000Z%2F20220301T170000Z&details=Join%20us%20at%20the%20live%20coding%20session%2C%20where%20we%E2%80%99ll%20show%20you%20how%20to%20customize%20the%20Chat%20Widget%20experience%20using%20LiveChat%20SDKsand%20brand%20new%20Chat%20Widget%20Adapters.%0A%0ABecome%20part%20of%20the%20LiveChat%20developer%20community%20to%20make%20the%20most%20of%20your%20LiveChat%20account%20and%20monetize%20apps.%0A%0A%3Cb%3EWhen%3C%2Fb%3E%3A%20March%201%2C%202022%20at%205%20p.m.%20CET%0A%3Cb%3EWhere%3C%2Fb%3E%3A%20%3Ca%20href%3D%22https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DxSRsKhhM4hM%22%20target%3D%22_blank%22%20rel%3D%22noopener%20noreferrer%22%3EYouTube%3C%2Fa%3E%0A%3Cb%3EHost%3C%2Fb%3E%3A%20%3Ca%20href%3D%22https%3A%2F%2Ftwitter.com%2Fwalaszczyk_m%22%20target%3D%22_blank%22%20rel%3D%22noopener%20noreferrer%22%3EMaciej%20Walaszczyk%3C%2Fa%3E%20Senior%20Frontend%20Developer&text=Live%20Coding%20Session%3A%20Customize%20the%20Chat%20Widget%20experience"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="c-btn">Add to Calendar</button>
          </a>
          <span className="u-m-md">and</span>
          <a
            href={process.env.GATSBY_CONSOLE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="c-btn">Join Developer Console</button>
          </a>
        </div>

        <div className="u-Mt-lg u-Mb-sm u-flex u-flex-col u-items-center">
          <h3 className="u-text-p5">See our previous sessions:</h3>
          <a
            className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat u-text-blue"
            href="https://youtu.be/d792twx7Q0I"
            target="_blank"
            rel="noopener noreferrer"
          >
            LiveChat Design System in use
          </a>
          <a
            className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat u-text-blue"
            href="https://www.youtube.com/watch?v=ei8YG9mLdfI"
            target="_blank"
            rel="noopener noreferrer"
          >
            Server-side App Authorization
          </a>
          <a
            className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat u-text-blue"
            href="https://youtu.be/FDmLqEiCH60"
            target="_blank"
            rel="noopener noreferrer"
          >
            Authorizing web apps
          </a>
          <a
            className="u-w-fit u-mt-xs u-mb-xs u-text-p6-bold c-link u-text-livechat u-text-blue"
            href="https://www.youtube.com/watch?v=SjqCBgAaEbc&feature=youtu.be"
            target="_blank"
            rel="noopener noreferrer"
          >
            Building LiveChat apps 101
          </a>
        </div>
      </section>
    </Layout>
  )
}

export default LiveCoding
